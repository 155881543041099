
import { defineComponent, reactive, ref, onMounted, computed } from "vue";
import TheLogo from "@/components/TheLogo.vue";
import { Invoice } from "@/types/Invoice";
import { apiLoadInvoice, apiUpdateInvoiceBilledTo } from "@/api/invoices";
import { convertMoney } from "@/common/helper";
import { PrinterOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "InvoiceForm",
  components: {
    TheLogo,
    PrinterOutlined,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const invoice: Partial<Invoice> = reactive({});
    const isLoadInvoiceCheck = ref(false);
    const isBilledToViewBox = ref(false);
    const billedTo = ref("");

    const loadInvoice = async () => {
      const { data } = await apiLoadInvoice(props.id);
      Object.assign(invoice, data);
      if (data.data && data.data.billedTo) {
        billedTo.value = data.data.billedTo;
      }
      isLoadInvoiceCheck.value = true;
    };

    onMounted(loadInvoice);

    const openBilledTo = () => {
      isBilledToViewBox.value = !isBilledToViewBox.value;
    };

    const invoiceVatNumber = computed(() => {
      return invoice.data?.vatNumber;
    });

    const saveBilledTo = async () => {
      const { data } = await apiUpdateInvoiceBilledTo(props.id, billedTo.value);
      Object.assign(invoice, data);
      if (data.data && data.data.billedTo) {
        billedTo.value = data.data.billedTo;
      }
      openBilledTo();
      isLoadInvoiceCheck.value = true;
    };

    const print = () => {
      window.print();
    };

    return {
      invoice,
      isBilledToViewBox,
      isLoadInvoiceCheck,
      convertMoney,
      print,
      openBilledTo,
      saveBilledTo,
      invoiceVatNumber,
      billedTo,
    };
  },
});
